.gpt3__cert-container_article {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    background: var(--color-footer);
    border-radius: 40px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 25px;
}

.gpt3__cert-container_article-image {
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.gpt3__cert-container_article-image img {
    max-height: 300px;
    width: auto;
    height: auto;
    border-radius: 15px;
}

.gpt3__cert-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.gpt3__cert-container_article-content p {
    margin-top: 14px;
    font-family: var(--font-family);
    font-size: 11.65px;
    font-weight: 700;

    color: #fff;
}

.gpt3__cert-container_article-content h3 {
    font-family: var(--font-family);
    font-size: 25.11px;
    font-weight: 800;
    line-height: 30.30px;
    
    color: #fff;
    cursor: pointer;
}

.gpt3__cert-container_article-content p:last-child {
    cursor: pointer;
}

@media screen and (max-width: 750px) {
    .gpt3__cert-container_article {
        margin-right: 0px;
        margin-left: 0px;
    }

    .gpt3__cert-container_article-image img {
        max-height: unset;
        max-width: 100%;
    }
}


@media screen and (max-width: 550px) {
    .gpt3__cert-container_article-content h3 {
        font-size: 18px;
        line-height: 25p;
    }
    .gpt3__cert-container_article-image img {
        max-height: unset;
        max-width: 100%;
    }
}