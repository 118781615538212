@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #4F98CA 1.84%, #50D890 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #4F98CA -13.86%, #50D890 99.55%);
  
  --color-bg: #EFFFFB;
  --color-footer : #272727;
  --color-blog: #EFFFFB;
  --color-text: #272727;
  --color-subtext: #4F98CA;
}
