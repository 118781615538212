.gpt3__footer {
    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.gpt3__put-center {
    justify-content: center;
    align-items: center;
}

.gpt3__footer-heading {
    width: 100%;
}

.display-row {
    display: flex;
    flex-direction: row;
}

.display-column {
    margin-right: 3rem;
}

/* .whatsapp {
    background: #4CAF50;
    border: none;
    border-radius: 25px;
    color: #fff;
    cursor: pointer;
    font-size: 1.25em;
    letter-spacing: .1em;
    outline: none;
    padding: .75em 2em;
    position: relative;
    text-transform: uppercase;
    font-family: var(--font-family);
    
    &:hover {
      animation: glow 1s ease-in infinite;
    }
    
    &::before {
      animation: glow 1s ease-in infinite;
      background: #4CAF50;
      border-radius: inherit;
      content: '';
      filter: blur(10px);
      opacity: .75;
      position: absolute;
      transition: all .3s ease-in-out;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      z-index: -1;
    }
    
    &:hover::before {
      filter: blur(20px);
      opacity: 1;
    }
  }
  
  @keyframes glow {
    0% {
      background: #4CAF50;
    }
    25% {
      background: #66BB6A;
    }
    100% {
      background: #4CAF50;
    }
  } */

.whatsapp {
    flex: 0.6;
    width: 420px;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    /* background: #fff; */
    /* border: 2px solid #fff; */
    border: 0px;
    /* padding: 0 1rem; */
    color: #272727;
    cursor: pointer;
    outline: none;
    
    background: transparent;
    box-sizing: border-box;
    border-radius: 0.6rem;
    text-transform: uppercase;

    margin-bottom: 3rem
}

.whatsapp {
    background-image: linear-gradient(to right, #4CAF50, #27df2d, #4CAF50);
    background-size: 400% 100%;
    transition: all .5s ease-in-out;
    transform: translateY(50%);
}

.whatsapp:hover {
    background-position: 100% 0;
}

.whatsapp i {
    margin-right: 10px;
    font-size: 20px;
}

.gpt3__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
}

.gpt3__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;

    margin-bottom: 10rem;
    cursor: pointer;
}

.gpt3__footer-content__input {
    width: 100%;
    margin-top: 1rem;

    display: flex;
    flex-direction: column;
}

.gpt3__footer-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #272727;
    border: 2px solid #EFFFFB;
    margin-bottom: 2rem;
    padding: 1rem;
    outline: none;
    color: #EFFFFB;

    border-radius: 15px;
}

.gpt3__footer-content__input textarea {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #272727;
    border: 2px solid #EFFFFB;
    margin-bottom: 2rem;
    padding: 1rem;
    outline: none;
    color: #EFFFFB;

    border-radius: 15px;
}

.gpt3__footer-content__input-button {
    flex: 0.6;
    width: 420px;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #fff;
    border: 2px solid #fff;
    padding: 0 1rem;
    color: #272727;
    cursor: pointer;
    outline: none;

    border-radius: 10px;
}

.gpt3__footer-btn p {
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 21px;
    color: #fff;
    word-spacing: 2px;
}

.gpt3__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 3rem;
    width: 100%;
    text-align: left;
}

.gpt3__footer-links div {
    width: 250px;
    margin: 1rem;
}

.gpt3__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.gpt3__footer-links_logo img {
    width: 118px;
    height: 30px;

    margin-bottom: 1rem;
}

.gpt3__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #fff;
}

.gpt3__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.gpt3__footer-links_div h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;

    margin-bottom: 0.9rem;
}

.gpt3__footer-links_div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.5rem 0;
    cursor: pointer;
}

.gpt3__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.gpt3__footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}

@media screen and (max-width: 1400px) {
    .display-row {
        display: flex;
        flex-direction: column;
    }

    .display-column {
        margin-right: 0rem;
    }
}

@media screen and (max-width: 850px) {
    .gpt3__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .gpt3__footer-links div {
        margin: 1rem 0;
    }

    .gpt3__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }

    .gpt3__footer-content__input-button {
        width: 100%;
    }

    .whatsapp {
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .gpt3__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}
